<script>
import { getFilterRoute } from './shop-list-utils'
export default {
	props: {
		appliedFilters: Array,
	},
	methods: {
		getFilterRoute(type, value, added) {
			return getFilterRoute(this.$route, type, value, added)
		},
	},
}
</script>

<template>
	<div v-if="appliedFilters.length">
		<v-chip
			v-for="item of appliedFilters"
			:key="item.text"
			class="mt-2 mr-4"
			close
			small
			@click="$router.push(getFilterRoute(item.type, item.value, false))"
			@click:close="$router.push(getFilterRoute(item.type, item.value, false))"
		>
			{{ item.text | truncate(25) }}
		</v-chip>
	</div>
</template>
